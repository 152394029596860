<script>
import { appModule } from '@/store/modules/app.module';

export default {
  name: 'AccommodationVTour',
  data() {
    return {
      steps: [
        {
          target: '[data-v-step="9"]',
          header: {
            title: 'You can <span style="font-weight:700">create your Accommodations</span>',
          },
          content: 'by selecting this button and entering the accommodation information into the relevant fields.',
          params: {
            placement: 'top-end',
            enableScrolling: false,
          },
        },
      ],
      myCallbacks: {
        onPreviousStep: this.prevStepCallback,
        onNextStep: this.nextStepCallback,
        onSkip: this.onSkip,
        onFinish: this.onFinish,
      },
    };
  },
  mounted() {
    const AccommodationVTourSee = JSON.parse(window.localStorage.getItem('AccommodationVTourSee'));
    if (AccommodationVTourSee == null || AccommodationVTourSee) {
      setTimeout(() => {
        this.$tours.AccommodationVTour.start();
      }, 1000);
    }
  },
  methods: {
    prevStepCallback(currentStep) {
      if (currentStep === 2) {
        /*         console.log(currentStep);
         */
      }
    },
    nextStepCallback(currentStep) {
      if (currentStep === 1) {
        /*         console.log(currentStep);
         */
      }
    },
    onSkip(currentStep) {
      appModule.updateTourActive(false);
      window.localStorage.setItem('NavVTourSee', false);
      window.localStorage.setItem('BookingVTourSee', false);
      window.localStorage.setItem('AccommodationVTourSee', false);
    },
    onFinish() {
      appModule.updateTourActive(false);
      window.localStorage.setItem('AccommodationVTourSee', false);
    },
  },
};
</script>
<template>
  <v-tour
    class="AccommodationVTour"
    name="AccommodationVTour"
    :steps="steps"
    :options="{ highlight: false }"
    :callbacks="myCallbacks"
  ></v-tour>
</template>

<style lang="scss">
.AccommodationVTour .v-step {
  top: -1% !important;
  left: 70% !important;
}
.v-step,
.v-step__header {
  background: #ea5455 !important;
}
.AccommodationVTour .v-step__arrow {
  left: 80% !important;
  border-color: #ea5455 !important;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
</style>
