















import Component from 'vue-class-component';
import { Provide, PropSync } from 'vue-property-decorator';
import AccommodationModal from '@/modules/accommodation/components/AccommodationModal.vue';
import Upload from '@/components/Upload.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import Vue from 'vue';
import qr from '@/assets/images/svg/qrcode.svg';
import Table from '@/components/table/Table.vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import AccommodationEdit from '@/modules/accommodation/components/AccommodationEdit.vue';
// import { Loading } from '../../auth/store/auth.service';
import VsDivider from '../../../components/vx-divider/vsDivider.vue';
import { AccommodationDto } from '../../../app/entities/tenant/accommodation.dto';
import { appModule } from '@/store/modules/app.module';
import { mapState } from 'vuex';
import isEmpty from '../../../app/utils/object.utils';
import VueTour3 from '@/components/v-tour/VueTour3.vue';
import { customPrint } from '@/app/utils/print';
import userModule from '../../../store/modules/user/user.module';
import { guestModule } from '../../../store/modules/guest.module';
import NewAccommodation from '@/pages/organisation/accommodations/new-accommodation.vue';

@Component({
  components: {
    AccommodationModal,
    Upload,
    AccommodationEdit,
    Pagination,
    qr,
    VsDivider,
    Table,
    VueQrcode,
    VueTour3,
    NewAccommodation,
  },
  computed: {
    ...mapState('app', ['tourActive']),
  },
  name: 'accommodations',
  layout: 'AppMain',
})
export default class AccommodationView extends Vue {
  @PropSync('editing') editingSync: boolean;

  @Provide() activeIndex = 0;

  search = '';

  selected: AccommodationDto = {} as AccommodationDto;

  popupActive = false;

  itemsPerPage = 4;

  isMounted = false;

  isFlipped = false;

  sidebarData = {};

  index = 0;

  isEdit = false;

  page = 1;

  max = 5;

  check = false;

  async created() {
    await this.fetch();
  }

  async refreshDataList() {
    await this.fetch();
  }

  async fetch() {
    if (Object.keys(tenantModule?.accommodations).length === 0) {
      await tenantModule.getAccommodations();
    }
  }

  get accommodations(): AccommodationDto[] {
    if (!tenantModule.accommodations) return [];
    return tenantModule.accommodations;
  }

  get tourActive() {
    return isEmpty(appModule.tourActive) && this.$route.path.includes('organisation');
  }

  get isMember() {
    return !this.$ability.can('create', 'accommodation');
  }

  get accommodationId() {
    return userModule.member[0].accommodationId;
  }

  update(update: AccommodationDto): void {
    const index = this.accommodations.findIndex((x: any) => x.id === update.id);

    this.accommodations.splice(index, 1, update);
  }

  mounted(): void {
    this.isMounted = true;
  }

  setModalTarget(accommodation: AccommodationDto, index: any): void {
    this.activeIndex = index;
    this.selected = accommodation;
    this.check = true;
  }

  qrCode(code: string): string {
    return `${process.env.VUE_APP_URL}/guests/create?token=${code}&org=${tenantModule.tenant.id}`;
  }

  print() {
    customPrint('printQr', {
      name: '_blank',
      specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
      styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',
        'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
      ],
      timeout: 1000,
      autoClose: true,
      windowTitle: window.document.title,
    });
  }

  editAccommodation(accommodation: AccommodationDto, index: number): void {
    this.activeIndex = index;
    // this.selected = accommodation;
    this.$emit('isEditing', accommodation);
    this.$router.push('accommodations/new-accommodation');
  }

  // this needs to be cleaned up
  addNewAccommodation(isEdit: boolean): void {
    if (!isEdit) {
      this.selected = {} as AccommodationDto;
    }
    this.isEdit = isEdit;
    this.$emit('newAccommodation');
    this.$router.push('accommodations/new-accommodation');
  }

  //   @Loading
  async updateAccommodation(accommodation: AccommodationDto, index: number): Promise<void> {
    await this.$store.dispatch('accommodation/update', accommodation);

    const payload = {
      entity: accommodation,
      index,
    };

    this.$store.commit('accommodation/updateOneList', payload);
  }

  deleteData(id: number) {
    this.$store.dispatch('dataList/removeItem', id).catch((err) => {
      console.log(err);
    });
  }

  async deleteAccommodation(accommodation: AccommodationDto): Promise<any> {
    const swal = await this.$swal({
      title: 'Delete',
      text: 'Are you sure you want to delete this accommodation?',
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      showDenyButton: true,
      denyButtonText: 'No',
    });
    if (swal.isConfirmed) {
      try {
        let status: any[] = [];
        await guestModule.findAllBookings();
        Object.values(guestModule.bookings).forEach(async (booking) => {
          if (booking.accommodation.id === accommodation.id) {
            booking.guest.forEach((guest: any) => {
              status.push(guest.status);
            });
          }
        });
        if (status.includes('CHECKEDIN')) {
          this.$swal(`You can't do that`, `Accommodations with guests that are checked in can't be deleted.`, 'error');
        } else {
          await tenantModule.deleteAccommodation(accommodation.id as string);
          await tenantModule.getAccommodations();
          this.$swal(`Deleted!`, `Your accommodation has been deleted.`, 'success');
        }
      } catch (e) {
        this.$swal(`An error occurred`, `You can't delete that accommodation.`, 'error');
      }
    }
  }
}
