
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import Editor from '@tinymce/tinymce-vue';
import { mixins } from 'vue-class-component';
import Upload from '@/components/Upload.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Loading } from '@/modules/auth/store/auth.service';
import GoogleMapInput from '@/components/google-map/GoogleMapInput.vue';
import Tinymce from '@/components/Tinymce/index.vue';
import CibButton from '@/components/buttons/CibButton.vue';
import { Auth } from '@/store/modules/auth.module';
import Stepper from '@/app/components/stepper/Stepper.vue';
import * as _ from 'lodash';
import attachment from '@/components/attachment/attachment.vue';
import { isEmpty } from 'lodash';
import AccommodationSchema from '@/modules/accommodation/schema/accommodation.schema';
import { getHashMap } from '@/app/utils/array';
import { AccommodationDto, AccommodationDtoDocuments, IIndemnity } from '../../../app/entities/tenant/accommodation.dto';
import { appModule } from '../../../store/modules/app.module';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({
  components: {
    Upload,
    Editor,
    Tinymce,
    GoogleMapInput,
    CibButton,
    Stepper,
    attachment,
  },
})
export default class AccommodationModal extends mixins(AccommodationSchema) {
  @Ref('form') readonly form: any;

  @Ref('detailsForm') readonly detailsForm: FormulateForm;

  @Ref('healthDisclaimerForm') readonly healthDisclaimerForm: FormulateForm;

  @Ref('documentForm') documentForm: any;

  @PropSync('active') activeSync!: boolean;

  @Prop({ default: () => null }) accommodationEdit: any;

  $refs!: any;

  baseUrl = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/upload/`;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  tinyModel = '';

  logoSub = 'Accommodation logo';

  vsAlert = true;

  hasLogoSubmit = false;

  logo = [];

  currentStep = 0;

  steps = ['Accomodation details', 'Customise check-in form', 'Health questionnaire', 'Location', 'Indemnities & terms', 'Extra documents'];

  singleDocument: any = {
    attachEmail: false,
  };

  isLoading = false;

  _ = _;

  accommodation: AccommodationDto = new AccommodationDto();

  isOn = false;

  activeDoc = 0;

  questions: Record<string, any>[] = [];

  selectedQuestions: number[] = [];

  accommodationIndemnity: IIndemnity[] = [
    {
      name: '',
      form: '',
    },
  ] as IIndemnity[];

  directions = { url: '' } as any;

  editDirections: { url: ''; name: '' }[] | null = null;

  editedAccomodation: AccommodationDto;

  @Watch('currentStep')
  checkStep(newStep: number, oldStep: number) {
    /** i keep a copy of the filledin accom form on submit
    and use that to fill if they go back a step */
    if (newStep == 0 && newStep < oldStep) {
      this.$nextTick(() => {
        this.accommodation = this.editedAccomodation;
        /*         this.detailsSchema[0].children[0].value.url = '/path/to/document.pdf';
         */
      });
    }
  }
  hasQuestion(id: number) {
    return this.selectedQuestions.some((key) => key === id);
  }

  addRemove(id: number) {
    const index = this.selectedQuestions.findIndex((key) => key === id);

    if (index >= 0) {
      this.selectedQuestions = this.selectedQuestions.filter((ele) => ele !== id);
    } else {
      this.selectedQuestions.push(id);
    }
  }
  get schema() {
    return [
      {
        component: 'div',
        class: 'grid grid-cols-2 gap-2',
        'keep-model-data': true,

        children: [
          {
            type: 'image',
            name: 'file',
            id: Math.random().toString(),
            // 'upload-url': '/your/upload/directory',
            label: "Upload your accommodation's logo:",
            // placeholder: 'Accommodation name',
            // help: 'Select a png, jpg or gif to upload.',
            validation: 'required',
            'upload-behavior': 'delayed',
            value: this.logo,
            'keep-model-data': true,

            // validation: 'mime:image/jpeg,image/png,image/gif',
            class: 'col-span-2',
          },

          {
            type: 'text',
            name: 'name',
            label: 'Accommodation name',
            placeholder: 'Accommodation name',
            validation: 'required',
            'validation-name': 'Accommodation Name',
            'keep-model-data': true,
            class: 'col-span-1',
          },
          {
            type: 'select',
            label: 'Type of accommodation',
            name: 'typeId',
            validation: 'required',
            'validation-name': 'Type',
            'keep-model-data': true,
            class: 'col-span-1',
            options: appModule.accommodationType.map((v: any) => {
              return { value: v.id, label: v.name };
            }),
          },
          {
            name: 'contactPerson',
            label: `Contact person's name`,
            validation: 'required',
            placeholder: 'Contact person',
            'validation-name': 'Contact person',
            'keep-model-data': true,
            class: 'col-span-1',
          },
          {
            type: 'email',
            name: 'email',
            label: 'Email',
            placeholder: 'Notification email',
            validation: 'required|email',
            'validation-name': 'Email',
            'keep-model-data': true,
            class: 'col-span-1',
          },
          {
            type: 'countryCode',
            label: 'Phone number',
            name: 'phone',
            'keep-model-data': true,
            'validation-messages': {
              phone: 'Phone is required',
            },
            'error-behaviour': 'blur',
            validation: 'required|phone',
          },
          {
            type: 'countryCode',
            label: 'Emergency phone number',
            name: 'emergencyNumber',
            'keep-model-data': true,
            'validation-messages': {
              phone: 'Phone is required',
            },
            validation: 'required|phone',
            'validation-behaviour': 'live',
          },
          {
            component: 'div',
            'keep-model-data': true,

            class: 'col-span-2 flex gap-2 pr-2',
            children: [
              {
                type: 'number',
                name: 'rooms',
                class: 'col-span-2 w-1/2',
                label: 'Number of rooms',
                validation: 'min:1,value',
                value: '1',
                'keep-model-data': true,
                'validation-name': 'Total rooms',
              },
            ],
          },

          {
            component: 'div',
            'keep-model-data': true,

            class: 'col-span-1 flex',
            children: [
              {
                type: 'timepicker',
                name: 'checkInTimeStart',
                pattern: '[0-9]*',
                class: 'col-span-1 flex',
                label: 'Check-in from',
                validation: 'required',
                'keep-model-data': true,
                'validation-name': 'Check-in time',
              },
              {
                type: 'timepicker',
                name: 'checkInTimeEnd',
                label: 'To',
                class: 'col-span-1 flex gap-2',
                pattern: '[0-9]*',
                'keep-model-data': true,
              },
            ],
          },
          {
            type: 'timepicker',
            label: 'Check-out time',
            name: 'checkOutTime',
            // pattern: '[0-9]*',
            validation: 'required',
            'keep-model-data': true,
            'validation-name': 'Check-out time',
            class: 'col-span-1 w-1/2 flex',
          },
        ],
      },
    ];
  }
  created() {
    if (!isEmpty(this.accommodationEdit)) {
      this.accommodation = this.accommodationEdit;
      this.accommodationIndemnity = this.accommodationEdit.indemnity;
      this.logo = this.accommodationEdit.logo;
      // this.detailsSchema[0].children[0].value = this.accommodationEdit.logo as any;
      if (this.accommodationEdit.directions) {
        if (this.accommodationEdit.directions.url == '' && this.accommodationEdit.directions.name == '') {
          this.editDirections = null as any;
        } else {
          this.editDirections = [this.accommodationEdit.directions];
        }
      } else {
        this.editDirections = null as any;
      }
    } else {
      this.accommodation.address = {
        name: '',
        formatted_address: '',
        formatted_phone_number: '',
        url: '',
        international_phone_number: '',
      };
    }
    this.directions = this.accommodation.address;
    this.directions.directions = this.editDirections;

    this.questions = appModule.questions;

    this.selectedQuestions = this.questions.map((question) => question.id);
  }

  mounted() {
    // this.$emitter.on('uploaded', (e) => {
    //   console.log(this.accommodation.logo);
    //   this.accommodation.logo = e.path.url;
    // });
  }

  addIndemnity() {
    this.accommodationIndemnity.push({ name: '', form: '' });
    this.activeDoc += 1;
  }

  removeIndemnity(index: number) {
    if (this.accommodationIndemnity.length !== 1) this.accommodationIndemnity.splice(index, 1);
  }

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  get uploadUrl(): string | undefined {
    return this.baseUrl;
  }

  get isMember() {
    return !this.$ability.can('create', 'accommodation');
  }

  deleteAccommodation(id: any, i: any): void {
    this.$emit('clicked', id, i);
  }

  validate(form: FormulateForm, args: string): boolean {
    return form?.formSubmitted() && args !== '';
  }
  event(e: any) {
    console.log(e);
  }

  next(step: number) {
    if (step) console.log(step);
  }
  validateNext(e: number) {
    switch (e) {
      case 0:
      case 1:
        if (this.accommodation.healthDisclaimer === undefined || '') {
          this.$swal('No Disclaimer', 'Please a disclaimer for your health questionnaire.', 'error');
          break;
        } else {
          this.currentStep += 1;
        }
        break;
      case 2:
        if (this.accommodation.address.name === '') {
          this.$swal('No Address', 'Please add an address', 'error');
          break;
        } else {
          this.currentStep += 1;
        }
        break;
      case 3:
        /*         console.log(`ref-${e}`);
        this.$refs[`ref-${e}`].formSubmitted(); */
        /*         if (this.$refs[`ref-${e}`].hasErrors) {
         */
        if (this.accommodationIndemnity[this.activeDoc].name === '') {
          this.$swal('No Terms or Indemnity', 'Please add at least one indemnity', 'error');
          return;
        } else if (this.accommodationIndemnity[this.activeDoc].form === '') {
          this.$swal('No Body', 'Please fill in the body of the form', 'error');
          return;
        } else {
          this.currentStep += 1;
        }
        break;
      case 4:
        /*         console.log('documentForm');
         */ this.$refs.documentForm.formSubmitted();
        if (this.$refs.documentForm.hasErrors) {
          return;
        } else {
          this.currentStep += 1;
        }
    }
  }
  @Loading
  async complete(): Promise<void> {
    let payload = _.omit(this.accommodation, 'file');
    payload.phone = getHashMap(this.accommodation.phone);
    payload.emergencyNumber = getHashMap(this.accommodation.emergencyNumber);
    payload.indemnity = this.accommodationIndemnity;
    payload.logo = this.logo[0];
    payload.rooms = parseInt(this.accommodation.rooms as any, 10);
    payload.latitude = this.accommodation.address.latitude;
    payload.longitude = this.accommodation.address.longitude;

    payload.healthQuestions = this.selectedQuestions.map((id) => {
      return {
        questionId: id,
      };
    });

    if (this.directions.directions?.files?.length) {
      payload.directions = {
        url: this.directions.directions.files[0]?.path?.url,
        name: this.directions.directions.files[0]?.path?.name,
      };
    } else {
      payload.directions = {
        url: '',
        name: '',
      };
    }

    Object.keys(this.accommodation).find((v) => {
      if (v.includes('image_')) {
        /*         console.log(v);
         */ payload.logo = this.accommodation[v].files[0].path.url;
        payload = _.omit(payload, v) as any;
      }
    });

    // console.log('Payload:');
    // console.log(payload);

    try {
      this.accommodation = await tenantModule.createAccommodation(payload as AccommodationDto);
      this.submit();
    } catch (error: any) {
      console.log(error);
    }
  }

  @Loading
  async submit(): Promise<void> {
    tenantModule.getAccommodations();
    if (!isEmpty(this.accommodationEdit)) {
      this.$swal('Updated', `${this.accommodationEdit.name} updated successfully`, 'info');
    } else {
      this.$swal('Created!', 'Your accommodation has been created. Start adding bookings to invite your guests', 'success');
    }
    this.activeSync = false;
  }

  submitHandler(data: any): any {
    this.editedAccomodation = data;
    this.detailsSchema[0].children[1].value = data.file;
    this.accommodation = data;
    this.accommodation.healthDisclaimer = data;
    this.logo = data.file;
    this.currentStep += 1;

    // console.log('Accommodation:');
    // console.log(this.accommodation);
  }

  documentSubmitHandler(val: any): any {
    const docObj = val;
    const url = val.file[0].url;
    docObj.url = url;
    const doc = new AccommodationDtoDocuments(docObj);
    this.accommodation.accommodationDocuments.push(doc);
    this.$formulate.reset('f-1');
    this.singleDocument = {
      attachEmail: false,
    };
  }

  deleteRow(e: any) {
    const index = this.accommodation.accommodationDocuments.indexOf(e);
    this.accommodation.accommodationDocuments.splice(index, 1);
  }

  public success(event: any): void {
    this.accommodation.logo = event[0].data;
  }

  getFormOptions(options: Record<string, any>[]) {
    /*     console.log(options);
     */ if (options) return _.omit(_.omit(options, 'optionGroupId'), 'optionGroupId');
    // let mappedOptions: any = {};

    // options.forEach((v) => {
    //   mappedOptions[v.id] = v.value;
    // });
    // console.log(mappedOptions);
    // return mappedOptions;
  }
}
