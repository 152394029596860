
import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import accommodationSchema from '@/modules/accommodation/schema/accommodation.schema';
import contactSchema from '@/modules/accommodation/schema/contact.schema';
import Upload from '@/components/Upload.vue';
import service from '@/services/app-http-client';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import Tinymce from '@/components/Tinymce/index.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import { Auth } from '@/store/modules/auth.module';
import { AccommodationDto, IAddressDto } from '@/app/entities/tenant/accommodation.dto';

@Component({
  components: {
    Upload,
    Tinymce,
    Editor,
    ConfirmModal,
  },
})
export default class AccommodationEdit extends Vue {
  @PropSync('accommodation') accommodationSync!: AccommodationDto;

  @PropSync('active') activeSync!: boolean;

  @Ref('input') readonly input: any;

  @Prop() index!: number;

  accommodationSchema = accommodationSchema;

  contactSchema = contactSchema;

  tinyModel = '';

  activeTab = 0;

  vsAlert = true;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  get logo() {
    return {
      url: this.accommodationSync.logo as string,
      name: 'logo',
      type: 'image/*',
      id: this.accommodationSync.id as string,
    };
  }

  async updateAccommodation(): Promise<void> {
    const loading = this.$vs.loading();

    const res = await service.patch(`/accommodation/${this.accommodationSync.id}`, this.accommodationSync);
    loading.close();
    await tenantModule.getAccommodations();

    this.accommodationSync = res.data;
    this.activeSync = false;
  }

  jdata = {};

  gMapFunc(event: { lat: () => any; lng: () => any }) {
    this.jdata = { geo: { lat: event.lat(), lng: event.lng() } };
  }

  public success(event: any): void {
    this.accommodationSync.logo = event[0].data;
  }

  get accommodationMarker(): Partial<IAddressDto> {
    return this.accommodationSync.address as Partial<IAddressDto>;
  }

  get address(): Partial<IAddressDto> {
    return this.accommodationSync.address as Partial<IAddressDto>;
  }

  get geometry(): {
    lat: any;
    lng: any;
  } {
    if (this.address.geometry) {
      this.address.latitude = this.address.geometry.location.lat();
      this.address.longitude = this.address.geometry.location.lng();
    }

    if (!this.accommodationSync.address) {
      throw new Error();
    }
    return {
      lat: this.accommodationSync.address.latitude || 28.047,
      lng: this.accommodationSync.address.longitude || 28.047,
    };
  }

  async deleteAccommodation(): Promise<any> {
    const response = await this.$swal({
      icon: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      position: 'center',
      showConfirmButton: true,
      confirmButtonText: 'Accept',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
    if (response.isConfirmed) {
      await tenantModule.deleteAccommodation(this.accommodationSync.id as string);
      this.$swal('Deleted!', 'Your booking has been deleted.', 'success');
      this.activeSync = false;
    }
  }

  getAddressData(addressData: any): void {
    this.accommodationSync.address = addressData;
  }
}
