export default [
  {
    component: 'div',
    class: 'form-wrap',
    children: [
      {
        type: 'text',
        label: 'Phone number',
        name: 'phone',
        inputmode: 'numeric',
        pattern: '[0-9]*',
        placeholder: 'Phone number',
        help: 'Mobile or office number',
        validation: 'matches:/^[0-9-]+$/',
        'validation-messages': {
          matches: 'Phone number should only include numbers and dashes.',
        },
        'keep-model-data': true,
      },
      {
        type: 'text',
        label: 'Emergency number',
        name: 'emergencyNumber',
        inputmode: 'numeric',
        pattern: '[0-9]*',
        placeholder: 'Emergency number',
        help: 'Mobile or office number',
        validation: 'matches:/^[0-9-]+$/',
        'validation-messages': {
          matches: 'Emergency number should only include numbers and dashes.',
        },
        'keep-model-data': true,
      },
      {
        type: 'text',
        label: 'Front desk number',
        name: 'frontDeskNumber',
        inputmode: 'numeric',
        pattern: '[0-9]*',
        help: 'Mobile or office number',
        placeholder: 'Front desk number',
        validation: 'matches:/^[0-9-]+$/',
        'validation-messages': {
          matches: 'Front desk number should only include numbers and dashes.',
        },
        'keep-model-data': true,
      },
    ],
  },
];
